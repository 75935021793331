<template>
  <div>
    <!-- <el-dialog
            title="실시간 모니터링 영상"
            :visible.sync="liveViewDialog"
            append-to-body
            width="55%"
            top="10vh"
        >
            <el-container>
                <form-vms-popup
                    :fcltId="fcltData.fcltId"
                    :fcltTypeId="fcltData.fcltTypeId"
                    :protocolId="fcltData.fcltTypeData.vmsProtocolId.value"
                    :spotId="fcltData.fcltTypeData.vmsSpotId.value"
                    :rtmpUrl="fcltData.fcltTypeData.rtmpUrl.value"
                    ref="formVmsPopup"
                ></form-vms-popup>
            </el-container>
    </el-dialog>-->
    <div class="dk-card">
      <div>
        <el-carousel indicator-position="outside">
          <el-carousel-item v-for="(item, index) in vmsCurrentSchedule.vmsMessages" :key="index">
            <img :src="item.thumbImageFile" style="height: auto; width: 100%;" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div style="margin-bottom: 8px;">
      <el-button @click="liveViewDialogOpen"> <i class="fas fa-video"></i>&nbsp;VMS 모니터링 </el-button>
    </div>

    <dialog-vms-viewer ref="dialogVmsViewer"></dialog-vms-viewer>
    <fclt-plugin-status ref="fcltPluginStatus" :title="'VMS 상태정보'" :fcltId="fcltData.fcltId" :statHederInfo="statHederInfo"></fclt-plugin-status>
  </div>
</template>

<script>
import fcltPluginStatus from '@/traffic/widget/fcltPluginStatus.vue';
import dialogVmsViewer from './dialogVmsViewer.vue';

export default {
  components: {
    'fclt-plugin-status': fcltPluginStatus,
    'dialog-vms-viewer': dialogVmsViewer,
  },
  name: 'dk-vms',
  label: '도로 전광판',
  created() {
    let me = this;
  },
  beforeDestroy() {},
  mounted() {
    this.$nextTick(() => {
      this.selectVmsRow();
    });
  },
  data() {
    return {
      vmsDownloadedSchedule: null,
      vmsCurrentSchedule: {
        vmsMessages: [],
      },

      // statKeyWords: [
      //         "powerStatus",
      //         "doorStatus",
      //         "fanStatus",
      //         "heaterStatus",
      // ],
      statHederInfo: [
        {
          key: 'powerStatus',
          name: '전원',
          textInfo: {
            ERR: '에러',
            OFF: '꺼짐',
            ON: '정상',
          },
        },
        {
          key: 'doorStatus',
          name: '문 열림',
          textInfo: {
            ERR: '에러',
            OFF: '꺼짐',
            ON: '정상',
          },
        },
        {
          key: 'fanStatus',
          name: '팬 가동',
          textInfo: {
            ERR: '에러',
            OFF: '꺼짐',
            ON: '정상',
          },
        },
        {
          key: 'heaterStatus',
          name: '히터 가동',
          textInfo: {
            ERR: '에러',
            OFF: '꺼짐',
            ON: '정상',
          },
        },
      ],
    };
  },
  props: {
    fcltData: {
      required: true,
    },
  },
  methods: {
    selectVmsRow() {
      this.$http
        .get(`${this.$config.getServerConfig().core.api}/core/api/vmsDownloadedSchedule`, {
          params: {
            fcltId: this.fcltData.fcltId,
          },
        })
        // this.$store
        //   .dispatch('vmsDownloadedSchedule/getVmsDownloadedSchedule', {
        //     fcltId: this.fcltData.fcltId,
        //   })
        .then((result) => {
          if (result) {
            this.vmsDownloadedSchedule = result;
            this.vmsCurrentSchedule = this.vmsDownloadedSchedule.vmsSchedules.find((schedule) => {
              return schedule.vmsScheduleId == this.vmsDownloadedSchedule.currentScheduleId;
            });
            if (!this.vmsCurrentSchedule) {
              this.vmsCurrentSchedule = {
                vmsMessages: [],
              };
            }
          }
          this.$refs.fcltPluginStatus.updateFcltStatusInfo(this.fcltData.fcltId);
        });
    },
    liveViewDialogOpen() {
      this.$refs.dialogVmsViewer.showDialog(this.fcltData);
    },
  },
};
</script>

<style scoped></style>
