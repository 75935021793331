var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "dk-card" }, [
        _c(
          "div",
          [
            _c(
              "el-carousel",
              { attrs: { "indicator-position": "outside" } },
              _vm._l(_vm.vmsCurrentSchedule.vmsMessages, function(item, index) {
                return _c("el-carousel-item", { key: index }, [
                  _c("img", {
                    staticStyle: { height: "auto", width: "100%" },
                    attrs: { src: item.thumbImageFile }
                  })
                ])
              }),
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticStyle: { "margin-bottom": "8px" } },
        [
          _c("el-button", { on: { click: _vm.liveViewDialogOpen } }, [
            _c("i", { staticClass: "fas fa-video" }),
            _vm._v(" VMS 모니터링 ")
          ])
        ],
        1
      ),
      _c("dialog-vms-viewer", { ref: "dialogVmsViewer" }),
      _c("fclt-plugin-status", {
        ref: "fcltPluginStatus",
        attrs: {
          title: "VMS 상태정보",
          fcltId: _vm.fcltData.fcltId,
          statHederInfo: _vm.statHederInfo
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }